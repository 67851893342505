import { cloneDeep, uniqueId } from 'lodash';
import React, { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import AssetAddEditForm from '../../../../../forms/AssetAddEditForm/AssetAddEditForm';
import { ASSET_CATEGORIES, primaryBankAccountName } from '../../../../../helpers/constants';
import useAuth from '../../../../../hooks/useAuth';
import ExplainatoryVideoButton from '../../../../common/ExplainatoryVideoButton';
import useLocalization from '../../../../../hooks/useLocalization';

const AssetModal = ({
  show,
  toBeEditedAsset,
  onHide,
  strategy,
  showProgress,
  strategyYears = [],
  compTable,
  onAssetSubmit,
  incompleteYearsOfStrategy,
  activeCustomer,
  adminCompTable
}) => {
  const { user } = useAuth();
  const { translate } = useLocalization();

  const assetOptions = useMemo(() => strategy.assets, [strategy]);

  const initialFormValues = useMemo(
    () =>
      !toBeEditedAsset
        ? {
            category: ASSET_CATEGORIES.CASH,
            editMode: strategy.isDefaultStrategy ? 'Setup Mode' : 'Edit Mode',
            buyingYear: strategy.initialYear,
            buyingMonth: strategy.initialMonth,
            sellingMonth: strategy.initialMonth,
            onlyApartment: { buying: true, selling: true },
            linkedBankAccount: assetOptions.find(a => a.name === primaryBankAccountName)?._id,
            liquidationScore: 1,
            partialSales: [],
            rsuGrants: [],
            paperApartmentPayments: [],
            deposits: []
          }
        : cloneDeep({
            ...toBeEditedAsset,
            editMode: toBeEditedAsset.editMode ? 'Edit Mode' : 'Setup Mode',
            //fixed incomes have startYear instead of buyingYear
            buyingYear: toBeEditedAsset.buyingYear ?? toBeEditedAsset.startYear,
            buyingMonth: toBeEditedAsset.buyingMonth ?? toBeEditedAsset.startMonth,
            sellingYear: toBeEditedAsset.sellingYear ?? toBeEditedAsset.endYear,
            sellingMonth: toBeEditedAsset.sellingMonth ?? toBeEditedAsset.endMonth,
            partialSales: toBeEditedAsset?.partialSales.map(s => ({ ...s, id: uniqueId() })),
            rsuGrants: toBeEditedAsset?.rsuGrants.map(s => ({ ...s, id: uniqueId() })),
            paperApartmentPayments: toBeEditedAsset?.paperApartmentPayments.map(s => ({ ...s, id: uniqueId() }))
          }),
    [toBeEditedAsset, strategy, show]
  );

  return (
    <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">{translate(toBeEditedAsset ? 'update_asset' : 'add_new_asset')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto px-4">
        {show && (
          <>
            <div className="px-2">
              <ExplainatoryVideoButton videoKey="add_asset" />
            </div>

            <AssetAddEditForm
              initialFormValues={initialFormValues}
              onSubmit={onAssetSubmit}
              strategy={strategy}
              strategyYears={strategyYears}
              incompleteYearsOfStrategy={incompleteYearsOfStrategy}
              assetOptions={assetOptions}
              compTable={compTable}
              adminCompTable={adminCompTable}
              user={user}
              activeCustomer={activeCustomer}
              showProgress={showProgress}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AssetModal;
