import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ChonkyIconFA } from 'chonky-icon-fontawesome';
import { FileBrowser, FileNavbar, FileToolbar, FileList } from 'chonky';
import { ChonkyActions } from 'chonky';
import { toast } from 'react-toastify';
import useFileUpload from 'react-use-file-upload';
import CreateNewFolderModal from './CreateNewFolderModal';
import { FolderPlus, Google, HddStack } from 'react-bootstrap-icons';
import { googleDriveServices } from '../../../services/googleDriveServices';
import CircularProgressBar from '../circular-progress';
import useLocalization from '../../../hooks/useLocalization';
import AlertModal from '../AlertModal';
import { isAdmin } from '../../../helpers/global';
import useAuth from '../../../hooks/useAuth';
import Loader from '../Loader';
import { Button } from 'react-bootstrap';
import GoogleDriveIcon from '../../../assets/GoogleDriveIcon.png';
const getFilesForCurrentFolder = files => {
  return files.map(file => ({
    id: file.id,
    name: file.name,
    isDir: file.mimeType === 'application/vnd.google-apps.folder',
    parentId: file.parents ? file.parents[0] : null
  }));
};

const GoogleDriveManager = ({ googleDriveFolderId }) => {
  const { translate, isRTL } = useLocalization();
  const { user } = useAuth();
  const [showAddFileOverlay, setShowAddFileOverlay] = useState(false);
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const { files, handleDragDropEvent, setFiles } = useFileUpload();
  const [fetching, setFetching] = useState(false);
  const [filesFromDrive, setFilesFromDrive] = useState([]);

  const [fileTree, setFileTree] = useState({});

  const [currentFolderId, setCurrentFolderId] = useState('');
  const [openCreateNewFolderModal, setOpenNewFolderModal] = useState(null);
  const [deleteFileMeta, setDeleteFileMeta] = useState(null);

  const customActions = useMemo(
    () => [
      {
        id: 'upload_file',
        button: {
          name: 'Upload File',
          toolbar: true,
          icon: 'upload'
        }
      }
    ],
    []
  );

  const fileActions = useMemo(
    () => (isAdmin(user?.role) ? [...customActions, ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles] : []),
    [user, customActions]
  );

  const openGoogleDrive = () => {
    if (!googleDriveFolderId) toast.error('Drive not found');
    let fileURL = `https://drive.google.com/drive/u/0/folders/${googleDriveFolderId}`;
    window.open(fileURL);
  };

  const getFilesAndFolders = async folderId => {
    try {
      setFetching(true);
      let { response, error } = await googleDriveServices.getFilesAndFolder({
        folder: folderId,
        query: ''
      });
      if (error) {
        console.log(error);
      } else {
        setFilesFromDrive(response || []);
        setFileTree(prevHistory => ({
          ...prevHistory,
          [folderId]: getFilesForCurrentFolder(response) || []
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFetching(false);
    }
  };

  const uploadFile = async () => {
    try {
      let file = files[0];
      const formData = new FormData();
      formData.append('name', file.name);
      formData.append('mimeType', file.type);
      formData.append('parents', JSON.stringify([currentFolderId])); // Ensure the array is converted to JSON string
      formData.append('file', file);
      setUploadingFiles(true);
      let { response, error } = await googleDriveServices.uploadFiles(formData);
      if (error) {
        return toast.error(error);
      } else {
        let formattedResponse = { ...response, parentId: currentFolderId };
        setFilesFromDrive([...filesFromDrive, formattedResponse]);
        setFiles([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUploadingFiles(false);
    }
  };

  const createNewFolder = async folderName => {
    try {
      setOpenNewFolderModal({ showProgress: true });
      let { response, error } = await googleDriveServices.createNewFolder({
        name: folderName,
        parents: [currentFolderId]
      });
      if (error) {
        setOpenNewFolderModal({ showProgress: false });
        return toast.error(error);
      } else {
        let formattedResponse = { ...response, parentId: currentFolderId };
        setFilesFromDrive([...filesFromDrive, formattedResponse]);
        setFileTree(prevHistory => ({
          ...prevHistory,
          [currentFolderId]: [
            ...fileTree?.[currentFolderId],
            {
              ...formattedResponse,
              isDir: true
            }
          ]
        }));
        setOpenNewFolderModal(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const deleteFiles = async () => {
    try {
      let { fileToDelete } = deleteFileMeta;
      setDeleteFileMeta({ ...deleteFileMeta, showProgress: true });
      let { response, error } = await googleDriveServices.deleteFilesAndFolders(fileToDelete);
      if (error) {
        setDeleteFileMeta({ ...deleteFileMeta, showProgress: false });
        return toast.error(error);
      } else {
        let updatedFilesAndFolder = filesFromDrive?.filter(f => f?.id !== fileToDelete);
        setFilesFromDrive(updatedFilesAndFolder);
        setDeleteFileMeta(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileAction = useCallback(
    action => {
      if (uploadingFiles) return;
      if (action.id === ChonkyActions.OpenFiles.id && action.payload.files) {
        const targetFile = action.payload.files[0];
        if (targetFile.isDir) {
          setCurrentFolderId(targetFile.id);
        } else {
          let fileURL = `https://drive.google.com/file/d/${targetFile.id}/view`;
          window.open(fileURL);
        }
      } else if (action.id === ChonkyActions.OpenParentFolder.id) {
        const parentFolder = filesFromDrive.find(file => file.id === currentFolderId)?.parents[0];
        if (parentFolder) {
          setCurrentFolderId(parentFolder);
        }
      } else if (action.id === ChonkyActions.CreateFolder.id) {
        setOpenNewFolderModal(true);
      } else if (action.id === ChonkyActions.DeleteFiles.id) {
        let fileToDelete = action.state.selectedFilesForAction?.map(f => f?.id)[0];

        setDeleteFileMeta({
          fileToDelete
        });
      } else if (action.id === 'upload_file') {
        const input = document.createElement('input');
        input.type = 'file';
        input.onchange = file => {
          const selectedFiles = file.target.files[0];
          setFiles(file);
        };
        input.click();
      }
    },
    [uploadingFiles]
  );

  useEffect(() => {
    getFilesAndFolders(currentFolderId);
  }, [currentFolderId]);

  useEffect(() => {
    const handleDragLeave = e => {
      setShowAddFileOverlay(false);
    };

    const handleDrop = e => {
      setShowAddFileOverlay(false);
    };

    document.addEventListener('dragleave', handleDragLeave);
    document.addEventListener('drop', handleDrop);

    return () => {
      document.removeEventListener('dragleave', handleDragLeave);
      document.removeEventListener('drop', handleDrop);
    };
  }, []);

  useEffect(() => {
    if (files?.length > 0) {
      uploadFile();
    }
  }, [files]);

  useEffect(() => {
    setCurrentFolderId(googleDriveFolderId);
  }, [googleDriveFolderId]);

  if (!currentFolderId) return null;

  return (
    <div
      className=" position-relative"
      style={{ height: 'calc(100vh - 115px)' }}
      onDragEnter={e => {
        e.preventDefault();
        setShowAddFileOverlay(true);
        handleDragDropEvent(e);
      }}
      onDragOver={e => {
        e.preventDefault();
        setShowAddFileOverlay(true);
        handleDragDropEvent(e);
      }}
      onDrop={e => {
        e.preventDefault();
        setFiles(e);
        setShowAddFileOverlay(false);
        handleDragDropEvent(e);
      }}
    >
      <FileBrowser
        // disableSelection
        disableDragAndDropProvider
        disableDefaultFileActions
        files={getFilesForCurrentFolder(filesFromDrive)}
        fileActions={fileActions}
        folderChain={[
          {
            id: googleDriveFolderId,
            name: 'Root',
            isDir: true
          },
          ...getFolderChain(fileTree, currentFolderId)
        ]}
        onFileAction={handleFileAction}
        iconComponent={ChonkyIconFA}
      >
        <Button
          style={{ position: 'absolute', ...(isRTL ? { left: 10 } : { right: 10 }) }}
          variant="outline-dark"
          className="d-flex gap-1 align-items-center"
          onClick={openGoogleDrive}
        >
          <img src={GoogleDriveIcon} height={14} /> <h6 className="smallFont mb-0">{translate('open_google_drive')}</h6>
        </Button>
        <FileNavbar />
        <FileToolbar />
        {fetching ? (
          <Loader />
        ) : (
          <div className="position-relative h-100">
            {(uploadingFiles || showAddFileOverlay) && (
              <div
                className="position-absolute d-flex gap-2 justify-content-center align-items-center  h-100 w-100"
                style={{
                  zIndex: 100,
                  backdropFilter: 'blur(`5px)',
                  backgroundColor: 'rgba(255, 255, 255, 0.5)'
                }}
              >
                {uploadingFiles && (
                  <div
                    className="d-flex gap-2 align-items-center bg-success text-white p-2"
                    style={{ borderRadius: '20px', fontSize: 15 }}
                  >
                    <CircularProgressBar light />
                    <h6 className="mb-0">Uploading please wait...</h6>
                  </div>
                )}{' '}
                {showAddFileOverlay && (
                  <div
                    className="d-flex gap-2 align-items-center bg-success text-white p-2"
                    style={{ borderRadius: '20px', fontSize: 15 }}
                  >
                    <FolderPlus size={20} />
                    <h6 className="mb-0">{translate('drop_your_files_here')}</h6>{' '}
                  </div>
                )}
              </div>
            )}

            <FileList />
          </div>
        )}
      </FileBrowser>
      {openCreateNewFolderModal && (
        <CreateNewFolderModal
          show={openCreateNewFolderModal}
          onHide={() => setOpenNewFolderModal(false)}
          showProgress={openCreateNewFolderModal?.showProgress}
          onSubmit={createNewFolder}
        />
      )}
      <AlertModal
        show={Boolean(deleteFileMeta)}
        onHide={() => setDeleteFileMeta(null)}
        onDismissClick={() => setDeleteFileMeta(null)}
        onContinueClick={deleteFiles}
        showProgress={deleteFileMeta?.showProgress}
        alertText={translate('are_you_sure_to_delete_this_file')}
      />
    </div>
  );
};

const getFolderChain = (fileTree, currentFolderId) => {
  const folderChain = [];
  let currentFolder = findFolderInHistory(fileTree, currentFolderId);
  while (currentFolder) {
    folderChain.unshift(currentFolder);
    currentFolder = findFolderInHistory(fileTree, currentFolder.parentId);
  }
  return folderChain;
};

const findFolderInHistory = (fileTree, folderId) => {
  for (const folder of Object.values(fileTree)) {
    const foundFolder = folder.find(file => file.id === folderId);
    if (foundFolder) {
      return foundFolder;
    }
  }
  return null;
};

export default GoogleDriveManager;
