import { startCase } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Badge, Card } from 'react-bootstrap';
import { Arrow90degLeft, Arrow90degRight, ChevronRight } from 'react-bootstrap-icons';
import UnderlineButton from '../UnderlineButton';
import useLocalization from '../../../hooks/useLocalization';

function formatPath(path) {
  return path
    .map((segment, index, arr) => {
      if (Number.isInteger(segment)) {
        // If the segment is a number, we consider it as an index for user-friendly presentation
        // We add 1 because array indices start from 0, but for non-developers, it's more natural to start from 1
        return (segment + 1).toString();
      } else {
        // If the segment is a string, we format it using lodash's startCase function
        return startCase(segment);
      }
    })
    .join(' > '); // join the segments with ' > ' to form a path
}

const LogItem = ({ user, log, disabled, onUndoRedoClick }) => {
  const { translate } = useLocalization();
  return (
    <div className={`w-100 px-2 pb-2 mt-2 border rounded bg-${log.undone ? 'primary-light bg-opacity-50' : 'light'}`}>
      <div className="d-flex align-items-center">
        <div className="flex-grow-1">
          <div>
            <Badge bg={log.isCreate ? 'success' : 'primary'} className="text-white mx-1" style={{ fontSize: 10 }}>
              {translate(log.isCreate ? 'CREATE' : 'UPDATE')}
            </Badge>
          </div>
        </div>
        {!log.isCreate && (
          <UnderlineButton
            onClick={() => onUndoRedoClick(log.undone ? 'redo' : 'undo')}
            disabled={disabled}
            variant={log.undone ? 'primary' : 'danger'}
            Icon={log.undone ? Arrow90degRight : Arrow90degLeft}
            text={translate(log.undone ? 'redo' : 'undo')}
          />
        )}
      </div>

      <p className="smallFont px-1 mt-1 mb-0">
        <b>{log.user?.name}</b>{' '}
        <span>{translate(log.isCreate ? 'created_this_user_on' : 'made_following_changes_on')}</span>
        <span className="text-muted font-weight-bold">{moment(log?.['timestamp']).format('MMMM Do YYYY, h:mm a')}</span>
      </p>

      {!log.isCreate &&
        log &&
        log.diffs.map(l => (
          <Card className="border rounded mt-1">
            <Card.Header className="px-2 py-1 smallFont text-white bg-dark">
              <b>{formatPath(l.pathNames) || user?.name || '-'}</b>
            </Card.Header>
            <Card.Body className="p-2">
              <div>
                {l.changes.map(change => (
                  <>
                    <h6 className="mb-1 tiny">
                      {'change' in change ? (
                        <>
                          <b className="text-muted">{change.change.toUpperCase()}: </b>
                          {change.value?.name || 'N/A'}
                        </>
                      ) : (
                        <>
                          <b className="text-muted">{startCase(change.key)}: </b>

                          {!['number', 'boolean'].includes(typeof change?.old) && !change?.old
                            ? 'N/A'
                            : change?.old?.toString()}
                          <ChevronRight className="mx-1" size={8} />
                          {!['number', 'boolean'].includes(typeof change?.new) && !change?.new
                            ? 'N/A'
                            : change?.new?.toString()}
                        </>
                      )}
                    </h6>
                  </>
                ))}
              </div>
            </Card.Body>
          </Card>
        ))}
    </div>
  );
};

export default LogItem;
