import React, { useContext } from 'react';
import { Button, Col, Dropdown, FormCheck, Row } from 'react-bootstrap';
import { ArrowDown, ArrowUp, Funnel } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import AutoCompleteSearch from './AutoCompleteSearch';
import { snakeCase } from 'lodash';

const SearchArea = ({
  query,
  onQueryChange,
  onSearchClick,
  disabled,
  maxLimit,
  maxLimitOptions,
  onMaxLimitOptionChange,
  sortBy,
  sortByOptions,
  onSortByOptionChange,
  descSort,
  onDescSortButtonClick,
  onFilterButtonClick,
  showArchiveCheckbox,
  showArchived,
  onShowArchivedChange,
  recommendations = [],
  setRecommendations,
  onRecommendationClick,
  showOnlySearch,
  hideSearch
}) => {
  const { translate } = useContext(LocalizeContext);

  return (
    <Row className="px-2 px-md-3 mt-1">
      <Col xs={showOnlySearch ? 12 : 8} md={12} lg={showOnlySearch ? 12 : 7} className="px-1">
        <AutoCompleteSearch
          query={query}
          onQueryChange={onQueryChange}
          onSearchClick={onSearchClick}
          disabled={disabled}
          recommendations={recommendations}
          setRecommendations={setRecommendations}
          onRecommendationClick={onRecommendationClick}
        />
      </Col>
      {!showOnlySearch && (
        <>
          <Col xs={4} md={{ span: 4, offset: 2 }} lg={{ span: 2, offset: 0 }} className="px-1">
            <Dropdown className="mb-1 mb-md-0">
              <Dropdown.Toggle
                size="sm"
                disabled={disabled}
                className="w-100"
                variant="outline-dark"
                id="dropdown-basic"
              >
                {translate('showing')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {maxLimitOptions.map(option => (
                  <Dropdown.Item
                    active={option === maxLimit}
                    key={option}
                    onClick={() => onMaxLimitOptionChange(option)}
                  >
                    {translate(snakeCase(option)) || option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={8} md={4} lg={{ span: 1, offset: 0 }} className={`px-1`}>
            <Dropdown className="mb-1 mb-md-0">
              <Dropdown.Toggle
                size="sm"
                disabled={disabled}
                className="w-100"
                variant="outline-dark"
                id="dropdown-basic"
              >
                {translate('sort_by')}
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {sortByOptions.map(option => (
                  <Dropdown.Item active={option === sortBy} key={option} onClick={() => onSortByOptionChange(option)}>
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Col>
          <Col xs={2} md={1} lg={{ span: 1, offset: 0 }} className="px-1">
            <Button variant="dark" className="w-100" size="sm" disabled={disabled} onClick={onDescSortButtonClick}>
              {descSort ? <ArrowDown className="text-align-top" /> : <ArrowUp className="text-align-top" />}
            </Button>
          </Col>

          <Col xs={2} md={1} lg={1} className="px-1">
            <Button size="sm" variant="dark" className="w-100" onClick={onFilterButtonClick} disabled={disabled}>
              <Funnel className="align-text-top" />
            </Button>
          </Col>
          {showArchiveCheckbox && (
            <Col xs={6} className="px-1 d-flex gap-2 align-items-center px-3">
              <FormCheck
                disabled={disabled}
                style={{ fontSize: '14px' }}
                className="my-1 fw-bold"
                checked={showArchived}
                size="sm"
                onChange={e => onShowArchivedChange(e.target.checked)}
              />
              <h6 className="mb-0 smallFont">{translate('show_archived')}</h6>
            </Col>
          )}
          <Col xs={showArchiveCheckbox ? 6 : 12} className="d-none d-md-block">
            <p style={{ fontSize: '14px' }} className="text-end my-1">
              <b>{translate('showing')}: </b>
              <span className="text-muted me-3">{translate('showing_n_items', { n: maxLimit })}</span>

              <b>{translate('sorting_by')}: </b>
              <span className="text-muted">{sortBy}</span>
            </p>
          </Col>
        </>
      )}
    </Row>
  );
};

export default SearchArea;
