import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, ListGroup } from 'react-bootstrap';
import { ChevronDown, ChevronUp, Pin } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import TextInput from '../../../../form-generator/components/TextInput';
import { STATUS_INTEREST_IN_MY_COURSE } from '../../../../helpers/constants';

const CustomerItem = ({ customer: c, active, onSelect, translate, archived }) => (
  <ListGroup.Item
    style={{ fontSize: 14 }}
    className={`py-1 ${active ? '' : archived ? 'bg-secondary' : ''} `}
    action
    active={active}
    onClick={() => onSelect && onSelect(c)}
    key={c['_id']}
  >
    <h6 className={`smallFont fw-bold text-${active ? 'white' : 'dark'} mb-0`}>
      {c['boardName'] || c['name'] || translate('no_name')}
    </h6>
    <h6 className={`tiny text-${active ? 'white' : 'muted'} mb-0`}>{c['email'] || translate('no_email')}</h6>
  </ListGroup.Item>
);

const CustomerList = ({
  selectedStatus = 'All',
  setSelectedStatus,
  customers = [],
  activeId = '',
  onSelect,
  quickSearch = true,
  statusFilterInQuickSearch = true,
  onHoverInactive, // New prop
  hoverDelay = 1000 // New prop with default delay of 1000ms (1 second)
}) => {
  const [query, setQuery] = useState('');
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const { translate } = useContext(LocalizeContext);
  const [filteredUnarchivedCustomer, setFilteredUnarchivedCustomer] = useState([]);
  const [filteredArchivedCustomer, setFilteredArchivedCustomer] = useState([]);
  const [showArchivedCustomers, setShowArchivedCustomers] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    setFilteredCustomers(
      customers.filter(c => {
        const filterBySelectedStatus = selectedStatus === 'All' || !selectedStatus || c['status'] === selectedStatus;

        const filterByQuery =
          !query.toLowerCase().trim() ||
          ['name', 'email', 'boardName'].some(property =>
            c[property]
              ?.toLowerCase()
              .trim()
              .includes(query.toLowerCase().trim())
          );

        return filterBySelectedStatus && filterByQuery;
      })
    );
  }, [query, customers, selectedStatus]);

  useEffect(() => {
    setFilteredArchivedCustomer(filteredCustomers.filter(c => c.archived));
    setFilteredUnarchivedCustomer(filteredCustomers.filter(c => !c.archived));
  }, [filteredCustomers]);

  useEffect(() => {
    let hoverTimeout;
    if (!isHovering && onHoverInactive) {
      hoverTimeout = setTimeout(onHoverInactive, hoverDelay);
    }

    return () => clearTimeout(hoverTimeout);
  }, [isHovering, onHoverInactive, hoverDelay]);

  return (
    <div className="px-2" onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      {quickSearch && (
        <div className="d-flex mb-2">
          <div className="flex-grow-1 me-1">
            <TextInput size="sm" hint={translate('quick_search')} onChange={e => setQuery(e.target.value)} />
          </div>
          {statusFilterInQuickSearch && (
            <Dropdown>
              <Dropdown.Toggle size="sm" block variant="outline-primary" id="dropdown-basic">
                <Pin className="mr-3 align-text-bottom" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {['All', 'New', 'Active', 'Past', STATUS_INTEREST_IN_MY_COURSE].map(s => (
                  <Dropdown.Item
                    className={`${s === selectedStatus ? 'bg-primary text-white' : ''}`}
                    onClick={() => setSelectedStatus(s)}
                    key={s}
                  >
                    {s}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      )}
      {filteredArchivedCustomer.length > 0 && (
        <>
          <div className="d-flex mb-1 mx-1 align-items-center">
            <div>{showArchivedCustomers ? <ChevronUp size={8} /> : <ChevronDown size={8} />}</div>
            <div className="flex-grow-1 mx-1">
              <p
                className="mb-0 tiny text-primary hover-light"
                onClick={() => setShowArchivedCustomers(!showArchivedCustomers)}
              >
                {showArchivedCustomers ? 'Hide archived customers' : 'Show archived customers'}{' '}
                <b>({filteredArchivedCustomer.length})</b>
              </p>
            </div>
          </div>
          {showArchivedCustomers && (
            <ListGroup className="mb-3">
              {filteredArchivedCustomer.map(c => (
                <CustomerItem
                  key={c['_id']}
                  customer={c}
                  onSelect={onSelect}
                  active={Array.isArray(activeId) ? activeId?.includes(c?._id) : activeId === c['_id']}
                  translate={translate}
                  archived
                />
              ))}
            </ListGroup>
          )}
        </>
      )}
      <ListGroup>
        {filteredUnarchivedCustomer.length > 0 ? (
          filteredUnarchivedCustomer.map(c => (
            <CustomerItem
              key={c['_id']}
              customer={c}
              onSelect={onSelect}
              active={Array.isArray(activeId) ? activeId?.includes(c?._id) : activeId === c['_id']}
              translate={translate}
            />
          ))
        ) : (
          <h6 className="py-5 text-center border rounded tiny">No customers to show </h6>
        )}
      </ListGroup>
    </div>
  );
};

export default CustomerList;
