import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, Dropdown, FormControl, Modal, Row } from 'react-bootstrap';
import {
  BoxArrowUpRight,
  Clipboard,
  Envelope,
  Eye,
  EyeSlash,
  PersonCircle,
  PersonPlus,
  Telephone
} from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import OverViewColumns from '../../../common/overview-columns/OverViewColumns';
import { customerFormFields, editCrmContactDetailsFields } from '../../../../helpers/overviewColumnsFields';
import {
  ENDPOINTS,
  FINANCIAL_STRATEGIST_ROLE,
  REAL_ESTATE_ANALYST_ROLE,
  SALES_ROLE
} from '../../../../helpers/constants';
import HorizontalProgress from '../../../common/HorizontalProgress';
import useDebouncedEffect from '../../../../hooks/useDebouncedEffect';
import { makeApiRequests } from '../../../../helpers/api';
import { toast } from 'react-toastify';
import { snakeCase } from 'lodash';

const CustomerActions = ({ translate, customerAction = [] }) => (
  <Dropdown className="d-inline-block mx-1">
    <Dropdown.Toggle variant="white" size="sm"></Dropdown.Toggle>
    <Dropdown.Menu>
      {customerAction.map(({ onClick, text, Icon }) => (
        <Dropdown.Item onClick={onClick} className="mid">
          <Icon className="mx-2" />
          <span className="align-middle"> {translate(text)}</span>
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  </Dropdown>
);

const MatchingCRMContact = ({ onViewCRMContactClick, contact = {}, translate }) => {
  return (
    <div className="p-2 border rounded mt-2 mx-2">
      <h6 className="smallFont fw-bold">{translate('matching_contact_found')}:</h6>
      <div onClick={() => onViewCRMContactClick(contact)} className="border hover p-2 bg-primary-light rounded">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            {' '}
            <PersonCircle size={15} />{' '}
            <h6 className="mb-0 mx-1 mid fw-bold">
              {contact?.firstName} {contact?.lastName}
            </h6>
            <BoxArrowUpRight className="mx-1" size={15} />
          </div>
          <div style={{ fontSize: 10 }} className={`bg-dark px-2 py-0 text-white rounded`}>
            {translate(snakeCase(contact.status)) || contact.status}
          </div>
        </div>
        <div className="mt-1 d-flex">
          <div style={{ width: 20 }}>
            {' '}
            <Envelope size={15} />{' '}
          </div>

          <div className="mx-1 d-flex flex-wrap align-items-center">
            {' '}
            {contact?.emails?.map((email, index) => (
              <h6 key={email} className="mb-0 smallFont">
                {index !== 0 && ', '} {email}
              </h6>
            ))}
          </div>
        </div>{' '}
        <div className="mt-1 d-flex ">
          <div style={{ width: 20 }}>
            {' '}
            <Telephone size={15} />{' '}
          </div>
          <div className=" d-flex flex-wrap align-items-center">
            {contact?.phoneNumbers?.map((ph, index) => (
              <h6 key={ph} className="mb-0 smallFont">
                {index !== 0 && ', '} {ph}
              </h6>
            ))}
          </div>
        </div>{' '}
      </div>
    </div>
  );
};

const AddEditCustomerModal = ({
  show,
  onHide,
  user,
  users,
  onInviteClick,
  onCloneClick,
  onShowAllStrategiesClick,
  customer,
  showProgress,
  onChange,
  onCustomerSubmit,
  mode,
  onViewCRMContactClick
}) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const [matchingCRMContact, setMatchingCRMContact] = useState(null);
  const salespersons = useMemo(() => users?.filter(u => u?.role === SALES_ROLE), [users]);
  const financialStrategists = useMemo(() => users?.filter(u => u?.role === FINANCIAL_STRATEGIST_ROLE), [users]);
  const realEstateAnalysts = useMemo(() => users?.filter(u => u?.role === REAL_ESTATE_ANALYST_ROLE), [users]);

  const crmContact = useMemo(() => customer?.crmContact, [customer]);

  const customerFormField = useMemo(
    () => customerFormFields(translate, user, salespersons, financialStrategists, realEstateAnalysts, crmContact),
    [translate, salespersons, financialStrategists, realEstateAnalysts, crmContact]
  );

  const editCrmContactDetailsField = useMemo(
    () => editCrmContactDetailsFields(salespersons, financialStrategists, realEstateAnalysts),
    [salespersons, financialStrategists, realEstateAnalysts]
  );

  const getMatchingContactFromEmail = async email => {
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.FIND_CRM_CONTACT_FROM_EMAIL,
        requestBody: { email }
      });
      if (error) {
        toast.error(error);
        return;
      }
      setMatchingCRMContact(response);
      if (response && !customer?.boardName) {
        let boardName = `${response?.firstName || ''} ${response?.lastName || ''}`.trim();
        onChange({ ...customer, boardName });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useDebouncedEffect(
    () => {
      if (show && customer?.email && !crmContact) {
        getMatchingContactFromEmail(customer?.email);
      } else {
        setMatchingCRMContact(null);
      }
    },
    [customer?.email, show, mode, crmContact],
    500
  );

  useEffect(() => {
    setMatchingCRMContact(null);
  }, [show]);

  return (
    <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0 d-inline-block">
            {translate(mode === 'clone' ? 'clone_customer' : mode === 'edit' ? 'update_customer' : 'add_new_customer')}
          </h6>
          {mode === 'edit' && (
            <CustomerActions
              translate={translate}
              customerAction={[
                { onClick: onInviteClick, text: 'Send_Password_Reset_Link', Icon: PersonPlus },
                { onClick: onCloneClick, text: 'clone', Icon: Clipboard },
                {
                  onClick: onShowAllStrategiesClick,
                  text: customer?.strategies?.every(s => s.showToCustomer)
                    ? 'hide_all_strategies_from_customer'
                    : 'show_all_strategies_from_customer',
                  Icon: customer?.strategies?.every(s => s.showToCustomer) ? EyeSlash : Eye
                }
              ]}
            />
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <div>
          {/* board name */}
          <div className="d-flex flex-column gap-2 px-2">
            <h6 className="smallFont mb-0">{translate('board_name')}</h6>
            <FormControl
              size="sm"
              disabled={showProgress}
              value={customer?.boardName}
              onChange={e => onChange({ ...customer, boardName: e.target.value })}
            />
          </div>
          {Boolean(matchingCRMContact) && (
            <MatchingCRMContact
              contact={matchingCRMContact}
              onViewCRMContactClick={onViewCRMContactClick}
              translate={translate}
            />
          )}
          {crmContact && (
            <div className="border mt-2 mx-2">
              <div
                onClick={() => onViewCRMContactClick(crmContact)}
                className="hover px-2 d-flex align-items-center py-1 bg-primary-light border-bottom"
              >
                <PersonCircle /> <span className="smallFont mx-2 fw-bold">{translate('this_customer_is_linked')} </span>{' '}
                <BoxArrowUpRight size={12} />
              </div>
              <OverViewColumns
                parentContainerClassName="mid m-0 p-1 bg-light"
                inputCellStyle={{ fontSize: 14 }}
                cellSizeMD={12}
                data={crmContact}
                onDataChange={crmContact => {
                  onChange({ ...customer, crmContact });
                }}
                fields={editCrmContactDetailsField}
                isUpdating={showProgress}
                alwaysInEditMode={false}
                editable={true}
              />
            </div>
          )}
          <OverViewColumns
            parentContainerClassName="mid m-0 p-0"
            inputCellStyle={{ fontSize: 14 }}
            cellSizeMD={12}
            data={customer}
            onDataChange={onChange}
            fields={customerFormField}
            isUpdating={showProgress}
            alwaysInEditMode={false}
            editable={true}
          />
        </div>
        {showProgress && (
          <HorizontalProgress text={`${translate(mode === 'edit' ? 'updating_customer' : 'saving_customer')}...`} />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button size="sm" variant="primary" className="" disabled={showProgress} onClick={onCustomerSubmit}>
          {translate(mode === 'edit' ? 'update' : 'add')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddEditCustomerModal;
