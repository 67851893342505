import React, { useEffect, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import useLocalization from '../../hooks/useLocalization';

const setCaretPosition = (elem, caretPos) => {
  if (elem != null) {
    if (elem.createTextRange) {
      var range = elem.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      elem.focus();
      elem.setSelectionRange(caretPos, caretPos);
    }
  }
};

const formatPercentage = ({ value, endsWithDot, round }) => {
  const valueInNum = Number(value);
  if (isNaN(valueInNum)) return '';
  const numString = value.toString();

  // Check if there are more than two decimal places
  if (round && numString.includes('.') && numString.split('.')[1].length > 2) {
    return `${value.toFixed(2)} %`;
  }

  return `${numString}${endsWithDot ? '.' : ''} %`;
};

const PercentageInput = ({ value, onChange, disabled = false, className = '', ...props }) => {
  const formControlRef = useRef();
  const { isRTL } = useLocalization();
  const [inputValue, setInputValue] = useState(formatPercentage({ value: 0 }));
  const [isFocused, setIsFocused] = useState(false); // State to track focus

  useEffect(() => {
    if (value !== undefined && value !== null) {
      const numberValue = Number(value);
      if (isNaN(numberValue) || !isFinite(numberValue)) {
        onChange(0);
        setInputValue('');
        return;
      }
    }

    setInputValue(formatPercentage({ value, round: true }));
  }, [value]);

  const updateCaretPosition = () => {
    const textElem = formControlRef.current;
    if (!textElem || !isFocused) return;

    const text = textElem.value;
    const currentCaretPosition = textElem.selectionStart;

    // If caret is at the end or if focus event, set caret before "%"
    if (currentCaretPosition === text.length) {
      setCaretPosition(textElem, text.length - 2);
    }
  };

  useEffect(() => {
    updateCaretPosition();
  }, [inputValue]);

  const handleInputChange = e => {
    const rawValue = e.target.value.replace('%', '').trim();
    const sanitizedValue = rawValue.replace(/[^.\d.-]/g, '');

    const numberValue = parseFloat(sanitizedValue);
    if (!isNaN(numberValue)) {
      setInputValue(
        formatPercentage({
          value: numberValue,
          endsWithDot: sanitizedValue.endsWith('.')
        })
      );
      onChange(numberValue);
    } else {
      setInputValue(formatPercentage({ value: 0 }));
      onChange(0);
    }
  };

  return (
    <FormControl
      ref={formControlRef}
      disabled={disabled}
      type="text"
      size="sm"
      value={inputValue}
      onFocus={() => setIsFocused(true)} // Set focus state
      onBlur={() => setIsFocused(false)} // Reset focus state
      onChange={handleInputChange}
      dir={'ltr'}
      className={`text-${isRTL ? 'end' : 'start'} ${className}`}
      {...props}
    />
  );
};

export default PercentageInput;
