import React from 'react';
import AppModal from '../common/AppModal';
import { PersonCircle } from 'react-bootstrap-icons';
import Loader from '../common/Loader';
import CustomerList from '../admin/manage-users/customer/CustomerList';
import useLocalization from '../../hooks/useLocalization';

const ViewCustomersMatchingApartmentModal = ({ show, onHide, showProgress, customers }) => {
  const { translate } = useLocalization();
  return (
    <AppModal
      title={translate('customers_matching_this_apartment')}
      show={show}
      onHide={onHide}
      modalBodyClassName="p-1"
    >
      <div style={{ minHeight: 300 }}>
        {' '}
        {showProgress ? (
          <Loader />
        ) : customers?.length === 0 ? (
          <h6 className="smallFont text-center mt-2">{translate('no_matching_customer_found')}</h6>
        ) : (
          <CustomerList
            customers={customers}
            statusFilterInQuickSearch={false}
            onSelect={customer => {
              window.open(
                `${process.env.REACT_APP_APP_URL}/admin/manage-users/customer?q=${customer.name || customer.email}`,
                '_blank'
              );
            }}
          />
        )}
      </div>
    </AppModal>
  );
};

export default ViewCustomersMatchingApartmentModal;
